@import "../../mixins/aspect-ratio"
@import "../../mixins/px-to-percent"

.offer-gallery
	margin: 0 auto 30px
	&__items
		display: flex

	&__main-img
		overflow: hidden
		width: px-to-percent(770, 1170)
		flex: 0 0 px-to-percent(770, 1170)
		@include aspect-ratio(770, 550, offer-gallery__main-img-inner)
		&-inner
			cursor: pointer
			background-size: cover
			transition: transform 500ms ease-in-out
			img
				display: none
		&:hover
			.offer-gallery__main-img-inner
				transform: scale(1.1)
		&:focus
			.offer-gallery__main-img-inner
				transform: scale(1)



	&__small-img
		position: relative
		display: flex
		flex-direction: column
		justify-content: space-between
		width: 100%
		margin-left: 30px
		.offer-gallery__item
			height: calc(50% - 15px)



	&__item
		overflow: hidden
		display: block
		width: 100%
		height: 100%
		a
			position: relative
			display: block
			width: 100%
			height: 100%
			background-size: cover
			background-position: center center
			background-repeat: no-repeat
			transform: scale(1)
			transition: transform 500ms ease-in-out
			cursor: pointer
			&:hover
				transform: scale(1.1)
			img
				display: none

		&--video
			position: relative
			&:before
				z-index: 50
				position: absolute
				top: 50%
				left: 50%
				content: $icon-video-play
				font-size: 80px
				font-family: $icomoon-font-family
				transform: translate(-50%, -50%)
				color: $white
				pointer-events: none
			&:after
				z-index: 40
				content: ""
				position: absolute
				top: 0
				left: 0
				right: 0
				bottom: 0
				background-color: rgba($brand-blue, 0.6)
				pointer-events: none

	&__buttons
		opacity: 0.9
		position: absolute
		bottom: 15px
		right: 0
		left: 0
		margin: 0 15px
		text-align: center
		.btn
			margin: 10px 5px 0
			box-shadow: 0 2px 6px rgba($brand-blue, 0.4)
			background-color: rgba($white, 0.9)
			&:hover
				background-color: $white
				color: $brand-blue
			&[data-fancybox-trigger="virtual_tour"]
				font-size: 40px
				line-height: 1
				padding: 2px 6px
				.icon-virtual-tour
					line-height: 1 !important
					&:before
						position: relative
						top: 3px

	&__hidden-items
		display: none




@media screen and (max-width: 1100px)
	.offer-gallery
		&__buttons
			display: flex
			flex-wrap: wrap
			justify-content: center


@media screen and (max-width: 850px)
	.offer-gallery
		position: relative
		&__items
			flex-direction: column

		&__main-img
			flex: 0 0 100%
			width: 100%


		&__small-img
			position: static
			.offer-gallery__item
				display: none

		&__buttons
			display: block

