@mixin aspect-ratio($width, $height, $className, $forceMinWidth: false) {
	position: relative;
	@if($forceMinWidth) {
		min-width: $width + px;
	}
	&:before {
		display: block;
		content: "";
		width: 100%;
		padding-top: ($height / $width) * 100%;
	}
	& .#{$className} {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}
