.about-carousel
	position: relative

	&__control
		position: static
		width: 100%
		margin: 0 auto
		max-width: 1170px
		padding-bottom: 15px
		display: flex
		justify-content: flex-end
		.swiper-button-next, .swiper-button-prev
			position: static
			width: auto
			height: auto
			margin-right: 15px
			&:after, &:before
				display: none
		.swiper-button-prev

			transform: rotate(180deg)


	.swiper-slide
		height: auto
		background-color: $white
		padding: 20px
		text-align: center
		img
			width: 100%
		h3
			margin-bottom: 0
			margin-top: 20px
			@include font-size(20px)
			color: $primary
		p
			margin: 0
		a
			text-decoration: none

	&__main
		z-index: 10
		margin: 0 auto
		width: 100%

	&__shadow
		position: absolute
		margin: 0 auto
		top: 0
		left: 0
		right: 0
		width: 100%
		&.swiper
			overflow: visible
			opacity: 0.4
			filter: grayscale(1)

@media screen and (min-width: 320px)
	.about-carousel
		.swiper-slide
			//width: 50% !important

@media screen and (min-width: 640px)
	.about-carousel
		.swiper-slide
			//width: 100% !important

@media screen and (min-width: 1200px)
	.about-carousel
		&__main, &__shadow
			width: 1170px
		.swiper-slide
			width: 270px !important

// breakpointy jsou v carousel.js
@media screen and (max-width: 560px)
	.about-carousel
		.swiper-slide
			img
				width: 60%

