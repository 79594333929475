$icomoon-font-family: "arezidenceIcons" !default;
$icomoon-font-path: "fonts" !default;

$icon-bold-plus: "\e900";
$icon-download: "\e908";
$icon-menu: "\e901";
$icon-chevron-bottom: "\e902";
$icon-chevron-left: "\e903";
$icon-chevron-right: "\e904";
$icon-chevron-top: "\e905";
$icon-close: "\e906";
$icon-plus: "\e907";
$icon-search: "\e909";
$icon-video-play: "\e90a";
$icon-virtual-tour: "\e91a";

