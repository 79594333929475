.about-modal
	position: relative
	display: flex
	.btn-close
		position: absolute
		top: 0
		right: 0

	&__img
		width: 28%
		img
			width: 100%

	&__content
		width: 70%
		padding-left: 30px
		padding-top: 20px
		h3
			@include font-size(30px)
			span
				@include font-size(14px)


@include media-breakpoint-down(lg)
	.about-modal
		flex-direction: column
		&__img
			width: 70%
			margin: 50px auto 0
		&__content
			width: 100%
			padding-left: 0

.mortgage-broker
	img
		max-height: 210px

@media screen and (max-width: 560px)
	.mortgage-broker
		text-align: center
	.about-photo.w-100
		width: 60% !important
