.recommended-carousel
	position: relative
	
	&__control
		position: static
		width: 100%
		margin: 0 auto
		max-width: 1170px
		padding-bottom: 15px
		display: flex
		justify-content: flex-end
		.swiper-button-next, .swiper-button-prev
			position: static
			width: auto
			height: auto
			margin-right: 15px
			&:after, &:before
				display: none
		.swiper-button-prev
			
			transform: rotate(180deg)
			
	
	.swiper-slide
		height: auto
		text-align: left
		text-decoration: none
		background-color: $white
		img
			width: 100%
		& > div
			padding: 15px 20px
		p
			font-weight: $font-weight-bold
			@include font-size(24px)
			margin: 0
			&:nth-of-type(1)
				color: $black
			&:nth-of-type(2)
				color: $primary
		
	&__main
		z-index: 10
		margin: 0 auto
		width: 100%
		
	&__shadow
		position: absolute
		margin: 0 auto
		top: 0
		left: 0
		right: 0
		width: 100%
		&.swiper
			overflow: visible
			opacity: 0.4
			filter: grayscale(1)

@media screen and (min-width: 320px)
	.about-carousel
		.swiper-slide
			//width: 50% !important
			
@media screen and (min-width: 640px)
	.about-carousel
		.swiper-slide
			//width: 100% !important
			
@media screen and (min-width: 1200px)
	.about-carousel
		&__main, &__shadow
			width: 1170px
		.swiper-slide
			width: 270px !important
			