@import "../../mixins/px-to-percent"

.offer-sidebar
	&__inner
		background-color: $white
		padding: 30px
				
		
	.offer-agent-card
		max-width: 230px
		width: 100%
		margin: 0 auto
		text-align: center
		img
			width: 100%
			display: block
			
	
		
		
@media screen and (max-width: 850px)
	.offer-sidebar
		.offer-form
			max-width: 320px
			margin: 0 auto
				