@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?t5naah') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?t5naah') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?t5naah##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bold-plus {
  &:before {
    content: $icon-bold-plus; 
  }
}
.icon-download {
  &:before {
    content: $icon-download; 
  }
}
.icon-menu {
  &:before {
    content: $icon-menu; 
  }
}
.icon-chevron-bottom {
  &:before {
    content: $icon-chevron-bottom; 
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left; 
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right; 
  }
}
.icon-chevron-top {
  &:before {
    content: $icon-chevron-top; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-plus {
  &:before {
    content: $icon-plus; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-video-play {
  &:before {
    content: $icon-video-play; 
  }
}
.icon-virtual-tour {
  &:before {
    content: $icon-virtual-tour; 
  }
}

