.project-filter
	.btn-outline-primary
		background-color: #EDF3F7 !important
		&:hover, &:active
			background-color: #d9dfe3 !important
			color: $primary !important
	.btn-outline-primary[data-reset]
		border: 1px solid transparent !important
		background-color: transparent !important
		&:hover
			background-color: #d9dfe3 !important

.projects-block
	&__wrapper
		border-bottom: 1px solid $brand-blue-light
		&:last-of-type
			border-bottom: 0
	&__img
		a
			display: block
			position: relative
			div
				img
					width: 100%
					height: 100%
					object-fit: cover
					object-position: center
				span
					z-index: 50
					position: absolute
					font-weight: $font-weight-bold
					display: block
					top: 30px
					left: 0
					padding: 5px 25px
					color: $white
					font-size: 20px
					&.empty
						background-color: #F92F2A
					&.nearly-empty
						background-color: #A502B1

	&__control
		button[aria-expanded="true"]
			opacity: 0.5
			.icon-chevron-bottom
				transform: rotate(-180deg)


	&__table
		position: relative
		font-size: 14px
		.table-responsive
			overflow: hidden
		thead
			td
				font-weight: $font-weight-bold
				background-color: $brand-blue
				color: $white
		tbody
			tr
				&:nth-last-of-type(even):not(.sold):not(.reserved)
					background-color: $brand-blue-light
				&.reserved
					background-color: $brand-orange-light
					td:last-of-type
						color: $brand-orange
				&.sold
					td
						filter: grayscale(1)


				td
					font-weight: $font-weight-bold
					color: $primary
					border-bottom: 1px solid #EDF3F7

			tr:hover
				cursor: pointer
				td
					box-shadow: inset 0 1px 0 0 $primary, inset 0 -1px 0 0 $primary
					&:first-of-type
						box-shadow: inset 1px 0 0 0 $primary, inset 0 1px 0 0 $primary, inset 0 -1px 0 0 $primary
					&:last-of-type
						box-shadow: inset -1px 0 0 0 $primary, inset 0 1px 0 0 $primary, inset 0 -1px 0 0 $primary

@include media-breakpoint-down(md)
	.projects-block
		&__img
			a
				div
					span
						top: 15px
						font-size: 16px
						padding: 4px 15px


// project table
.projects-block__table
	.marks
		width: 170px
		div
			display: flex
			align-items: center
			justify-content: space-between
			span
				position: relative
				display: flex
				align-items: center
				justify-content: center
				// width: 20px
				height: 20px
				margin: 3px
				color: $white

				&.empty
					visibility: hidden
					&:after
						z-index: 20
						position: absolute
						visibility: visible
						content: ""
						width: 6px
						height: 6px
						background-color: lighten($brand-gray, 20%)
			.T
				background-color: #25b1d1
			.B
				background-color: #a8641c
			.Z
				background-color: #65be30
			.P
				background-color: #174d8b
			.G
				background-color: #b31a3a
			.S
				background-color: #374142

	[data-toggle-more-units]
		display: flex
		align-items: flex-end
		justify-content: center
		z-index: 50
		position: absolute
		bottom: 0
		left: 0
		right: 0
		height: 250px
		padding-bottom: 30px
		background: linear-gradient(0deg, $white 40%, rgba(255, 255, 255, 0) 100%)
	[data-more-units]
		max-height: 700px


@include media-breakpoint-down(md)
	.projects-block__table
		[data-toggle-more-units]
			margin-right: -24px
			margin-left: -24px
