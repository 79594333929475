@import "~bootstrap/scss/functions"
@import "./variables"
@import "~bootstrap/scss/variables"
@import "~bootstrap/scss/mixins"
@import "~bootstrap/scss/bootstrap"
@import "~@fancyapps/fancybox/dist/jquery.fancybox.min.css"
@import "./components/font-face"
@import "./components/icon-font/variables"
@import "./components/icon-font/style"

@import "./override"
@import "./components/layout"
@import "./components/header"
@import "./components/footer"
@import "./components/big-slider"
@import "./components/projects-block"
@import "./components/page-title"
@import "./components/map"
@import "./components/project-detail"
@import "./components/detail-information"
@import "./components/offer/offer-layout"
@import "./components/offer/offer-gallery"
@import "./components/offer/offer-information"
@import "./components/offer/offer-sidebar"
@import "./components/about-carousel"
@import "./components/recommended-carousel"
@import "./components/about-modal"
@import "./components/energy-building-performance"
@import "./components/mortgage-calc"
