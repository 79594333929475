.noUi-tooltip
	padding: 0
	border: 0
	font-size: 10px
	color: $primary

.noUi-target
	box-shadow: none
	border: 0

.noUi-connects
	background-color: $primary

.noUi-horizontal .noUi-handle
	height: 19px
	width: 19px
	border-radius: 50px
	box-shadow: none
	border: 2px solid $white
	background-color: $primary
	&:before, &:after
		display: none


.noUi-horizontal
	height: 6px

.mortgage-calc
	border: 1px solid $primary
	padding: 50px 60px
	max-width: 830px
	margin: 0 auto
	&--offer-detail
		max-width: 760px
		background-color: $white
		border: 0
		margin-top: -4 * $spacer
		margin-bottom: 5 * $spacer
		margin-left: 0
		margin-right: 0
		@include media-breakpoint-down(lg)
			padding: 30px !important
		@include media-breakpoint-down(md)
			margin-top: -20px

	&__select
		border-left: 3px solid $primary

	&__slides
		& > div
			display: flex
			margin-bottom: 30px

			& > div
				&:nth-of-type(2)
					width: 100%
					display: flex
					align-items: center
				&.form-row
					flex: 0 0 340px
					label
						width: 130px
					input
						width: 150px
						margin-right: 25px
				#customRange1, #customRange2
					width: 100%

	&__result
		padding: 22px 26px
		background-color: #F0F0F0


@include media-breakpoint-down(md)
	.mortgage-calc
		border: 0
		padding: 0
		&__result
			span.d-inline-block
				display: block !important
				width: 100%
		&__slides
			& > div
				flex-direction: column
				& > div
					&:nth-of-type(2)
						margin-top: 30px
						padding: 0 15px
					&.form-row
						flex: 0 0 auto
