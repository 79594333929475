.form-floating > .form-control:not(:active)
	& ~ label
		span
			background-color: transparent
			--bs-bg-opacity: 0



// Floating Label shown or focused
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown)
	border-color: $primary
	background-color: transparent !important
	font-weight: $font-weight-bold
	color: $primary
	& ~ label
		z-index: 5
		font-weight: $font-weight-bold
		color: $primary !important
		span
			display: inline-block
			padding: 3px 12px 0
			--bs-bg-opacity: 1 !important


.pagination
	.page-item:not(.disabled)
		&:first-child, &:last-child
			.page-link
				background-color: $primary
				color: $white
	.page-link
		font-weight: bold


	@include media-breakpoint-up(sm)
		.page-item
			margin: 0 0.4rem
		.page-link
			width: 3em
			height: 3em
			line-height: 2.3
			text-align: center
			border-radius: 6px
			font-size: $h6-font-size * 1.125
			font-weight: bold



.btn.btn-outline-primary.bg-white
	background-color: rgba($white, 0.8) !important
	&:hover, &:active, &:focus
		background-color: $white !important
		color: $primary

h2, h2
	font-size: 2rem
	font-weight: 600

h3, h3
	font-size: 2rem !important
	font-weight: 600 !important
