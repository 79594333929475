@import "../../mixins/px-to-percent"

.offer-information
	&__wrapper
		width: 100%
		display: flex
		justify-content: space-between

		.offer-description
			width: px-to-percent(770, 1170)

		.offer-sidebar
			width: px-to-percent(400, 1170)
			padding-left: 30px


@media screen and (max-width: 850px)
	.offer-information
		&__wrapper
			flex-wrap: wrap
			.offer-description
				width: 100%
			.offer-sidebar
				width: 100%
				max-width: 100%
				padding-left: 0
				margin-left: 0
				margin-top: 30px


@media screen and (max-width: 550px)
	.offer-information
		&__wrapper
			.offer-sidebar
			flex-wrap: wrap
			&__inner
				flex-direction: column
				