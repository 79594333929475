.project-detail__top-title
	position: relative
	padding-right: 140px
	& > a
		display: flex
		position: absolute
		top: 0
		right: 0
		text-decoration: none
		font-weight: $font-weight-bold
		img
			display: inline-block
			margin-right: 8px
			width: 21px
	h2
		@include font-size(2rem)

@include media-breakpoint-down(md)
	.project-detail__top-title
		padding-right: 0

@media screen and (max-width: 700px)
	.project-detail__top-title
		display: flex
		flex-direction: column
		& > a
			position: static


.project-detail__gallery
	a
		img
			width: 100%
			height: 100%
			object-fit: cover
			object-position: top center
	&-button
		position: relative
		top: -25px
		text-align: center
		.btn.btn-outline-primary
			background-color: $white
			&:hover
				color: $white
				background-color: $primary


.project-detail__contact
	display: flex
	& > div
		text-align: center
		width: 50%
		border-right: 1px solid rgba($primary, 0.8)
		&:last-of-type
			border-right: 0
		p
			margin: 0

@include media-breakpoint-down(md)
	.project-detail__contact
		flex-direction: column
		align-items: center
		& > div
			width: 100%
			max-width: 320px
			border-bottom: 1px solid $primary
			border-right: 0
			margin-bottom: 15px
			padding-bottom: 15px
			&:last-of-type
				border: 0
				margin-bottom: 0
				padding-bottom: 0
