.energy-efficiency-list
	z-index: 20
	position: relative
	text-align: center
	width: 30px
	height: 30px
	&--a
		background-color: $energy-rating--a
		.energy-efficiency-list__arrow-right
			border-left-color: $energy-rating--a
	&--b
		background-color: $energy-rating--b
		.energy-efficiency-list__arrow-right
			border-left-color: $energy-rating--b
	&--c
		background-color: $energy-rating--c
		.energy-efficiency-list__arrow-right
			border-left-color: $energy-rating--c
	&--d
		display: none
		background-color: $energy-rating--d
		.energy-efficiency-list__arrow-right
			border-left-color: $energy-rating--d
	&--e
		display: none
		background-color: $energy-rating--e
		.energy-efficiency-list__arrow-right
			border-left-color: $energy-rating--e
	&--f
		display: none
		background-color: $energy-rating--f
		.energy-efficiency-list__arrow-right
			border-left-color: $energy-rating--f
	&--g
		display: none
		background-color: $energy-rating--g
		.energy-efficiency-list__arrow-right
			border-left-color: $energy-rating--g
	&__label
		padding-top: 1px
		line-height: 30px
		font-size: 18px
		font-weight: bold
		color: $black
	&__arrow-right
		position: absolute
		top: 0
		right: -11px
		width: 0
		height: 0
		border-top: 15px solid transparent
		border-bottom: 15px solid transparent
		border-left: 11px solid transparent

	@media screen and (max-width: 783px)
		left: 0

.energy-efficiency-detail
	position: relative
	height: 32px
	display: inline-block
	align-items: center
	&--a
		background-color: $energy-rating--a
		.energy-efficiency-detail__arrow-left
			border-right-color: $energy-rating--a
	&--b
		background-color: $energy-rating--b
		.energy-efficiency-detail__arrow-left
			border-right-color: $energy-rating--b
	&--c
		background-color: $energy-rating--c
		.energy-efficiency-detail__arrow-left
			border-right-color: $energy-rating--c
	&--d
		background-color: $energy-rating--d
		.energy-efficiency-detail__arrow-left
			border-right-color: $energy-rating--d
	&--e
		background-color: $energy-rating--e
		.energy-efficiency-detail__arrow-left
			border-right-color: $energy-rating--e
	&--f
		background-color: $energy-rating--f
		.energy-efficiency-detail__arrow-left
			border-right-color: $energy-rating--f
	&--g
		background-color: $energy-rating--g
		.energy-efficiency-detail__arrow-left
			border-right-color: $energy-rating--g

	&__arrow-left
		position: absolute
		top: 0
		left: -11px
		width: 0
		height: 0
		border-top: 16px solid transparent
		border-bottom: 16px solid transparent
		border-right: 11px solid transparent
	&__type
		font-size: 22px !important
		display: inline-block
		width: 32px
		height: 32px
		line-height: 33px
		text-align: center
		font-weight: 700
	&__desc
		line-height: 1 !important
		text-transform: uppercase
		font-size: 11px !important
		min-width: 110px
		display: inline-block
		padding: 0 10px
		text-align: right


@include media-breakpoint-down(md)
	.energy-efficiency-detail
		margin-top: 1em
		margin-left: 1rem
		&__desc
			text-align: left
