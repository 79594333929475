@import "../mixins/px-to-percent"

.detail-information
	background-color: $white
	padding: 40px 30px 0

	&__data-wrapper
		display: flex
		flex-wrap: wrap
		justify-content: space-between
		
	&__data
		width: calc(50% - 15px)

		&-item
			display: flex
			width: 100%
			padding: 5px 0
			border-bottom: 1px solid $brand-blue-light
			&:last-of-type
				border-bottom: 0
				
			span
				&:first-of-type
					width: px-to-percent(180, 340)
					min-width: px-to-percent(180, 340)
					padding-right: 1em
					
					color: $brand-gray-dark
				&:last-of-type
					flex-grow: 1
					font-weight: $font-weight-bold
					color: $black

	&__buttons
		padding: 30px 0
		&-inner
			display: flex
			align-items: center
			justify-content: center
			flex-wrap: wrap
			.btn
				margin: 0 10px 15px



@media screen and (max-width: 1200px)
	.detail-information
		padding: 20px 15px 0
		


@media screen and (max-width: 850px)
	.detail-information
		&__data-item
			flex-wrap: nowrap
			span
				&:first-of-type
					width: 160px
					min-width: 160px
					padding-right: 15px



@media screen and (max-width: 700px)
	.detail-information
		&__data
			width: 100%
		&__data-item
			span
				&:first-of-type
					width: 50%
					min-width: 50%
					padding-right: 15px



@media screen and (max-width: 430px)
	.detail-information
		&__data-item
			font-size: 14px
			line-height: 1.25
