$white:                                                 #fff;
$black:                                                 #000;
$brand-orange:                                          #FFB42D;
$brand-orange-light:                                    #FFF7E8;
$brand-green:                                           #B0CE1F;
$brand-gray:                                            #6A6A6A;
$brand-gray-dark:                                       #374142;
$brand-blue:                                            #1E6B99;
$brand-blue-light:                                      #EDF3F7;
$brand-purple:                                          #A502B1;
$brand-red:                                             #F92F2A;
$brand-red-dark:                                        #B31A3A;
$brand-brown:                                           #A8641C;


$enable-negative-margins:                               true;
$min-contrast-ratio:                                    2.2;
$primary:                                               $brand-blue;
$secondary:                                             $brand-orange;
$dark:                                                  $brand-gray;
$light:                                                 $brand-blue-light;

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1200px,
	xxl: 1320px
);

// colors
$theme-colors: (
	"primary": $primary,
	"secondary": $secondary,
	"dark": $dark,
	"light": $light
);


$custom-colors: (
	"brand-gray": $brand-gray,
	"brand-orange-light": $brand-orange-light,
	"brand-blue": $brand-blue,
	"brand-blue-light": $brand-blue-light
);
$theme-colors: map-merge($theme-colors, $custom-colors);


$body-color:                                            $black;
$link-color:                                            $primary;


// carousel
$carousel-control-color:                                $secondary;


// buttons
$input-btn-padding-y:                                   0.6175rem;
$input-btn-padding-x:                                   2rem;

$btn-border-radius:                                     0;
$btn-font-weight:                                       700;
$btn-font-size:                                         15 / 16 + rem;

$btn-padding-y-lg:                                      1rem;
$btn-padding-x-lg:                                      2rem;
$btn-font-size-lg:                                      15 / 16 + rem;
$btn-border-radius-lg:                                  $btn-border-radius;


// pagination
$pagination-padding-y:                                  .375rem;
$pagination-padding-x:                                  .75rem;

$pagination-color:                                      $dark;
$pagination-bg:                                         $white;
$pagination-border-width:                               2px;
$pagination-border-radius:                              6px;
$pagination-border-color:                               $brand-blue-light;

$pagination-active-color:                               $primary;
$pagination-active-bg:                                  $white;
$pagination-active-border-color:                        $primary;

$pagination-hover-color:                                $dark;
$pagination-hover-bg:                                   $brand-blue-light;
$pagination-hover-border-color:                         $brand-blue-light;

$pagination-disabled-color:                             $white;
$pagination-disabled-bg:                                $brand-gray;
$pagination-disabled-border-color:                      $brand-gray;

// fonts

$font-size-base:                                        1rem;
$small-font-size:                                       .8125rem;

$line-height-base:                                      1.8;

$font-family-sans-serif:                                -apple-system, BlinkMacSystemFont, "Inter", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-weight-normal:                                    400;

$btn-font-family: $font-family-sans-serif;

$headings-line-height:                                  1.3;

$headings-font-weight:                                  800;
$display-font-weight:                                   800;

$font-weight-light:                                     300;
$font-weight-bold:                                      700;
$font-weight-extra-bold:                                800;
$form-label-font-weight:                                400;

$h1-font-size:                $font-size-base * 3.125;
$h2-font-size:                $font-size-base * 2.5;
$h3-font-size:                $font-size-base * 2.125;
$h4-font-size:                $font-size-base * 1.25;
$h5-font-size:                $font-size-base * 1.125;
$h6-font-size:                $font-size-base;

// modal
$modal-inner-padding:                                   1.5rem;
$modal-content-border-radius:                           0.6rem;
$modal-backdrop-bg:                                     #47566E;
$modal-backdrop-opacity:                                .9;
$btn-close-color:                                       $primary;
$btn-close-width:                                       2em;
$btn-close-opacity:                                     1;
$btn-close-hover-opacity:                               .8;

// forms
$input-border-radius:                                   0rem;
$input-focus-width:                                     0;
$input-focus-box-shadow:                                0 0 0 0 transaprent;
$input-focus-color:                                     $primary;



// input text
$input-padding-y:                                       0.315rem;
$input-padding-x:                                       1rem;
$input-border-color:                                    $brand-blue-light;

//// floating labels
$form-floating-label-transform:                         scale(.85) translateY(-1.55rem) translateX(1.35rem);
$form-floating-label-opacity:                           1;
$form-floating-padding-x:                               $input-padding-x;
$form-floating-padding-y:                               .625rem;
$form-floating-input-padding-t:                         .55rem;
$form-floating-input-padding-b:                         .45rem;
$form-floating-height:                                  add(3rem, 2px);

// checkboxes
$form-check-input-width:                                1.5em;
$form-check-input-border-radius:                        0;
$form-check-input-border:                               1px solid $brand-blue-light;

// radius
$border-radius:                                         .45rem;
$border-radius-sm:                                      .75rem;
$border-radius-lg:                                      1rem;

// PENB
$energy-rating--a:                                      #3d9a20;
$energy-rating--b:                                      #53b136;
$energy-rating--c:                                      #b0ce1f;
$energy-rating--d:                                      #e8de1d;
$energy-rating--e:                                      #fec02e;
$energy-rating--f:                                      #f88401;
$energy-rating--g:                                      #f02310;
