.big-slider
	background-color: $brand-blue-light

	.swiper-button-next, .swiper-button-prev
		top: calc(50% - 40px)

	.swiper-navigation
		bottom: 160px

	.swiper-pagination
		bottom: 2px
		opacity: 0.8
	.swiper-pagination-bullet
		position: relative
		width: 14px
		height: 14px
		margin: 0 7px !important
		background-color: $white  !important
		opacity: 1
		box-shadow: 0 0 10px #000
	.swiper-pagination-bullet-active
		background-color: $white !important
		&:after
			content: ""
			width: 11px
			height: 11px
			position: absolute
			top: 50%
			left: 50%
			border-radius: 50px
			background-color: $primary
			transform: translate(-50%, -50%)


	&__inner
		max-width: 1920px
		width: 100%
		margin: 0 auto
		position: relative

	&__img
		height: 770px
		&-inner
			margin: 0 auto
			height: 100%
			background-position: center top
			background-size: cover

	&__offer-slide
		&-footer
			position: absolute
			bottom: 0
			right: 0
			left: 0
			background-color: rgba($white, 0.8)
			&-inner
				display: flex
				flex-wrap: wrap
				align-items: center
				justify-content: space-between
				max-width: 1200px
				margin: 0 auto
				padding: 24px 24px 30px
				& > div
					&:nth-of-type(1)
						padding-right: 20px
					&:nth-of-type(2)
						display: flex
						flex-wrap: wrap
						padding: 20px 0
			p
				font-size: 24px

	&__custom-slide-content
		position: absolute
		top: 0
		left: 0
		right: 0
		bottom: 0
		display: flex
		align-items: center
		&-inner
			max-width: 930px
			width: 100%
			margin: 0 auto
			padding: 0 24px 0
			h1
				line-height: 1.1
				text-shadow: 0 0 20px rgba(0, 0, 0, 0.3)
				@include font-size(5rem)
				@include media-breakpoint-down(sm)
					@include font-size(2rem)
		&-tag
			span
				z-index: 50
				display: inline-block
				padding: 5px 25px
				color: $white
				font-size: 20px
				font-weight: $font-weight-bold
				&.empty
					background-color: #F92F2A
				&.nearly-empty
					background-color: #A502B1


	.navigation
		z-index: 50
		position: absolute
		width: 100%
		max-width: 1170px
		top: 50%
		left: 50%
		transform: translateX(-50%)
		.swiper-button-next:after, .swiper-button-prev:after
			text-shadow: 0 0 10px rgba($black, 0.8)



@include media-breakpoint-down(xl)
	.big-slider
		&__img
			height: 0
			padding: 32%
			&-inner
				position: absolute
				top: 0
				left: 0
				right: 0
				bottom: 0


@include media-breakpoint-down(lg)
	.big-slider
		&__custom-slide-content
			&-inner
				padding: 0 64px 0
		&__offer-slide-footer
			&-inner
				padding: 10px 24px 15px
				h2
					@include font-size(1.8rem)
				p
					@include font-size(1.4rem)



@include media-breakpoint-down(md)
	.big-slider
		&__img
			height: 0
			padding: 50%
		&__custom-slide-content
		&__offer-slide-footer
			&-inner
				h2
					@include font-size(1.5rem)
				p
					@include font-size(0.9rem)


@include media-breakpoint-down(sm)
	.big-slider
		&__img
			height: 0
			padding: 50%
		&__offer-slide-footer
			&-inner
				& > div
					&:nth-of-type(1)
						padding-right: 0
					&:nth-of-type(2)
						padding: 10px 0 20px
