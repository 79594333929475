.scrolled-down
	transform: translateY(-100%)
	transition: all 0.3s ease-in-out

.scrolled-up
	transform: translateY(0)
	transition: all 0.3s ease-in-out

.header
	position: relative
	z-index: 1050
	&__phone
		padding: 0 0.7rem
		border-left: 1px solid #88A4B2
		border-right: 1px solid #88A4B2
	.navbar
		min-height: 90px
	
	.navbar-brand
		width: 100%
		max-width: 260px

	.navbar-brand
		img
			display: block
	.nav-item
		margin: 0 0.9rem
		.nav-link
			font-size: 15px
			color: $primary
			&:hover, &.active
				box-shadow: none
			&.active
				font-weight: $font-weight-bold
					
				
	.navbar-toggler
		box-shadow: none
		border: 0
		&:hover, &:focus
			box-shadow: none
			border: 0

	&__search
		position: relative
		form
			position: absolute
			top: - $spacer
			right: 50px
			bottom: - $spacer
			input
				height: 100%
				line-height: 100%
				background-color: $white
				width: 420px
				border: 0
				&:focus
					box-shadow: none
					outline: none


.mobile-menu
	position: fixed
	z-index: 8000
	top: 0
	left: 0
	right: 0
	bottom: 0 
	min-height: 100vh
	min-height: -webkit-fill-available
	min-width: 100vw
	background-color: $primary
	overflow: auto
	
	&__inner
		
		padding: 20px 30px
		
	&__top
		button
			padding: 0
			margin: 0
			border: 0
			background: none

	&__links
		a
			display: block
			font-size: 24px
			width: 100%
			padding: 10px 0
			text-decoration: none
			border-bottom: 1px solid $white