.footer
	background-color: $brand-blue-light
	color: $white
	i
		font-size: 12px
		color: $primary
	h6
		font-weight: $font-weight-normal
		margin-top: 1rem
		font-size: $small-font-size
		color: $brand-blue
		text-transform: uppercase

	&__legal
		border-top: 1px solid $brand-blue
		background-color: $black
		a
			color: $white
			text-decoration: none
		a:hover
			text-decoration: underline

	&__inner
		padding-left: 40px
		&--border
			border-left: 10px solid $primary

	&__pages
		display: flex
		li
			position: relative
			margin: 0 8px
			padding-right: 8px
			&:after
				position: absolute
				right: -6px
				content: "|"
				color: $primary
			&:last-of-type
				&:after
					display: none
