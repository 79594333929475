@import "../../mixins/px-to-percent"

.offer-description
	&__text
		padding: 30px
		margin-bottom: 30px
		background-color: $white
		&-inner
			position: relative
			max-height: 30em
			overflow: hidden
			p
				margin-bottom: 1em
				&:last-of-type
					margin-bottom: 0
			&-opener
				position: absolute
				bottom: 0
				right: 0
				left: 0
				height: 15em
				text-align: center
				background: linear-gradient(0deg, $white 30%, rgba(255, 255, 255, 0) 100%)
				.btn
					position: absolute
					bottom: 0
					left: 50%
					transform: translateX(-50%)
					
@include media-breakpoint-down(sm)
	.offer-description
		&__text
			padding: 15px
